import axios from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import { Documents, PartnerResponse, PartnersDocuments } from '../../models/Partner/Partner'

const baseUrl = `${getEndpointUrl('company')}/v1/documents/partner`

export const getPartnerDocuments = async (partnerIdentifier: string): Promise<PartnersDocuments[]> => {
  return axios.get(`${baseUrl}/${partnerIdentifier}`).then((response) => response.data)
}

export const savePartnerDocumentFiles = async (
  partnerIdentifier: string,
  documents: { name: string; file: File }[]
): Promise<PartnersDocuments[]> => {
  const formData = new FormData()
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  documents.forEach(({ name, file }) => {
    formData.append('files', file)
    formData.append('documentNames', name)
  })

  return axios.post(`${baseUrl}/${partnerIdentifier}/files`, formData, config)
}

export const savePartnerDocumentInfos = async (
  partnerIdentifier: string,
  documents: Documents
): Promise<PartnersDocuments> => {
  return axios.put(`${baseUrl}/${partnerIdentifier}/info`, documents).then((response) => {
    return response.data
  })
}

export const savePartnerFacade = async (partnerIdentifier: string, file: File): Promise<PartnerResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.put(`${baseUrl}/${partnerIdentifier}/facade`, formData).then((response) => response.data)
}
