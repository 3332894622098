import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import AppContext, { AppContextType } from '../../../AppContext'
import InputText from '../../../components/Form/Input/Input'
import { type CompanyMinimalInfo } from '../../../models/Company/Company'
import { createVehicle, getVehicleByIdentifier, updateVehicle } from '../../../services/Vehicle/VehicleService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import Maintenance from '../../../components/Maintenance/Maintenance'
import type { Vehicle } from '../../../models/Vehicle/Vehicle'
import { getAllPartners } from '../../../services/Partner/PartnerService'
import SelectCompany, { type SelectCompanyOptions } from '../../../components/Form/Select/SelectCompany'

const validationSchema = yup.object({
  partnerIdentifier: yup.string().required('campo obrigatório'),
  license: yup.string().required('campo obrigatório'),
  make: yup.string(),
  model: yup.string(),
})

interface VehicleFormProps {
  isPageInMaintenance?: boolean
}

const VehicleForm: React.FC<VehicleFormProps> = ({ isPageInMaintenance }) => {
  const { setTitle, setCustomHeaderContent, setIsShowLoading, showAlert, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const resolver = useYupValidationResolver(validationSchema)
  const { control, formState, handleSubmit, setValue, reset } = useForm<Vehicle>({ resolver })
  const navigate = useNavigate()
  const { id } = useParams<{ id: string | undefined }>()
  const [partners, setPartners] = React.useState<CompanyMinimalInfo[]>([])
  const [selectedPartner, setSelectedPartner] = React.useState<CompanyMinimalInfo>()
  const onChangePartner = (event: React.SyntheticEvent, value: SelectCompanyOptions | null): void => {
    if (value) {
      setValue('partnerIdentifier', value?.identifier)
      setSelectedPartner(value)
    } else {
      setValue('partnerIdentifier', '')
      setSelectedPartner(undefined)
    }
  }
  const onSubmit: SubmitHandler<Vehicle> = (data: Vehicle) => {
    setIsShowLoading(true)
    if (id) {
      updateVehicle(id, data)
        .then(() => {
          showAlert('success', 'Veículo alterado com sucesso.')
          setIsShowLoading(false)
          navigate('/main/vehicle/list')
        })
        .catch(() => {
          showAlert('error', 'Erro ao editar veículo. Tente novamente mais tarde.')
        })
        .finally(() => {
          setIsShowLoading(false)
        })
    } else {
      createVehicle(data)
        .then(() => {
          showAlert('success', 'Veículo salvo com sucesso.')
          navigate('/main/vehicle/list')
        })
        .catch(() => {
          showAlert('error', 'Erro ao salvar veículo. Tente novamente mais tarde.')
        })
        .finally(() => {
          setIsShowLoading(false)
        })
    }
  }
  const setDefaultValues = React.useCallback(
    (vehicle: Vehicle): void => {
      reset(vehicle)
    },
    [reset]
  )

  const handleEditFormInit = React.useCallback(
    async (identifier: string): Promise<void> => {
      setTitle('Editar Veículo')
      setItemsBreadCrumb([
        ...defaultBreadCrumbItems,
        { label: 'Listar veículos', path: '/main/vehicle/list' },
        { label: 'Editar veículo', path: `/main/vehicle/form/${identifier}` },
      ])
      const vehicle = await getVehicleByIdentifier(identifier)
      setDefaultValues(vehicle)
    },
    [setDefaultValues, setTitle, setItemsBreadCrumb]
  )

  const handleNewFormInit = React.useCallback(async (): Promise<void> => {
    const partnerList = await getAllPartners()
    setTitle('Novo Veículo')
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar veículos', path: '/main/vehicle/list' },
      { label: 'Novo veículo', path: '/main/vehicle/form' },
    ])
    setPartners(partnerList)
    setSelectedPartner(partnerList[0])
    setValue('partnerIdentifier', partnerList[0].identifier)
  }, [setTitle, setItemsBreadCrumb, setPartners, setSelectedPartner, setValue])

  const handleInit = React.useCallback(async (): Promise<void> => {
    setCustomHeaderContent(<div />)
    try {
      if (id) {
        handleEditFormInit(id)
      } else {
        handleNewFormInit()
      }
    } catch {
      showAlert('error', 'Erro ao carregar Veículos.')
    }
  }, [id, showAlert, setCustomHeaderContent, handleEditFormInit, handleNewFormInit])

  React.useEffect(() => {
    if (!isPageInMaintenance) {
      setIsShowLoading(true)
      handleInit().finally(() => {
        setIsShowLoading(false)
      })
    }
  }, [handleInit, isPageInMaintenance, setIsShowLoading])

  return isPageInMaintenance ? (
    <Maintenance />
  ) : (
    <Card className="card card--form card-forms-styles" variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} key="partnerCompany">
            <SelectCompany
              id="partnerIdentifier"
              label="Empresa Parceira"
              errorText={formState.errors?.partnerIdentifier?.message}
              options={partners}
              onChange={onChangePartner}
              company={selectedPartner}
              disabled={id !== undefined}
            />
          </Grid>
          <Grid item xs={12} sm={12} key="license">
            <Controller
              name="license"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="license" label="Placa" errorText={formState.errors?.license?.message} {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} key="make">
            <Controller
              name="make"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="make" label="Marca" errorText={formState.errors?.make?.message} {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} key="model">
            <Controller
              name="model"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="model" label="Modelo" errorText={formState.errors?.model?.message} {...field} />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </Grid>
      </form>
    </Card>
  )
}

export default VehicleForm
