import axios from 'axios'

import getEndpointUrl from '../../utils/url-resolver'
import { PartnerMinimalResponse } from '../../models/Partner/Partner'

const baseUrl = `${getEndpointUrl('company')}/v1/customer-partners`

export const getPartnersByCustomerIdentifier = async (
  customerIdentifier: string
): Promise<PartnerMinimalResponse[]> => {
  return axios.get(`${baseUrl}/${customerIdentifier}`).then((response) => {
    if (!response) {
      return [] as PartnerMinimalResponse[]
    }
    return response.data
  })
}
