import React from 'react'
import { Grid, Typography } from '@mui/material'
import { GatheringRequest } from '../../../../models/Gathering/Gathering'
import { getClientByIdentifier } from '../../../../services/Client/ClientService'
import { getPartnerByIdentifier } from '../../../../services/Partner/PartnerService'
import { ClientResponseI } from '../../../../models/Client/Client'
import { PartnerResponse } from '../../../../models/Partner/Partner'
import useStyles, { smoothBlack } from './styles/GatheringConfirmationInfoModalStyle'
import maskCNPJ from '../../../../utils/masks/maskCNPJ'

interface GatheringCompaniesProps {
  gatheringInfo: GatheringRequest
}

const GatheringCompanies: React.FC<GatheringCompaniesProps> = ({ gatheringInfo }) => {
  const [generatorInfo, setGeneratorInfo] = React.useState<ClientResponseI | null>(null)
  const [transporterInfo, setTransporterInfo] = React.useState<PartnerResponse | null>(null)
  const [receiverInfo, setReceiverInfo] = React.useState<PartnerResponse | null>(null)
  const classes = useStyles()

  const getGeneratorInfo = async (): Promise<ClientResponseI> => {
    try {
      return await getClientByIdentifier(gatheringInfo.generatorIdentifier)
    } catch (error) {
      return {} as ClientResponseI
    }
  }

  const getTransporterInfo = async (): Promise<PartnerResponse> => {
    try {
      return await getPartnerByIdentifier(gatheringInfo.transporterIdentifier)
    } catch (error) {
      return {} as PartnerResponse
    }
  }

  const getReceiverInfo = async (): Promise<PartnerResponse> => {
    try {
      return await getPartnerByIdentifier(gatheringInfo.receiverIdentifier)
    } catch (error) {
      return {} as PartnerResponse
    }
  }

  const handleInit = (): void => {
    getGeneratorInfo().then((response) => {
      setGeneratorInfo(response)
    })
    getTransporterInfo().then((response) => {
      setTransporterInfo(response)
    })
    getReceiverInfo().then((response) => {
      setReceiverInfo(response)
    })
  }

  React.useEffect(() => {
    handleInit()
  }, [])

  const renderCompanyInfo = (title: string, name: string, cnpj: string, unitCode: number): JSX.Element => {
    return (
      <Grid container spacing={2} className={classes.companyGrid}>
        <Grid item xs={12}>
          <Typography variant="body1" data-cy={`confirmation-${title}`}>
            <strong>{title}</strong>: {name}{' '}
            <small style={{ color: smoothBlack }}>
              ({maskCNPJ(cnpj)} - {unitCode})
            </small>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Grid container spacing={2} className={classes.card}>
        {generatorInfo &&
          renderCompanyInfo(
            'Gerador',
            generatorInfo.primaryData.name,
            generatorInfo.primaryData.cnpj,
            generatorInfo.primaryData.mtrSystemAccess.mtrSystemUnitCode
          )}
        {transporterInfo &&
          renderCompanyInfo(
            'Transportador',
            transporterInfo.primaryData.name,
            transporterInfo.primaryData.cnpj,
            transporterInfo.primaryData.unitCode
          )}
        {receiverInfo &&
          renderCompanyInfo(
            'Destinador',
            receiverInfo.primaryData.name,
            receiverInfo.primaryData.cnpj,
            receiverInfo.primaryData.unitCode
          )}
      </Grid>
    </div>
  )
}

export default GatheringCompanies
