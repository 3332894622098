import axios from 'axios'
import getEndpointUrl from '../../utils/url-resolver'
import { ClientContract, ClientContractFileInfo } from '../../models/Client/ClientContract'

const baseUrl = `${getEndpointUrl('company')}/v1/customers`

const createClientContract = async (
  clientContract: ClientContract,
  customerIdentifier: string
): Promise<ClientContract> => {
  return axios.post(`${baseUrl}/${customerIdentifier}/contracts`, clientContract).then((response) => response.data)
}

const updateClientContract = async (
  clientContract: ClientContract,
  customerIdentifier: string
): Promise<ClientContract> => {
  return axios.put(`${baseUrl}/${customerIdentifier}/contracts`, clientContract).then((response) => response.data)
}

const getCustomerContractsFilesList = async (
  customerIdentifier: string,
  contractIdentifier: string
): Promise<ClientContractFileInfo[]> => {
  return axios
    .get(`${baseUrl}/${customerIdentifier}/contracts/${contractIdentifier}/files`)
    .then((response) => response.data)
}

const getLatestContract = async (
  customerIdentifier: string,
  contractIdentifier: string
): Promise<ClientContractFileInfo> => {
  const contractFileList = await getCustomerContractsFilesList(customerIdentifier, contractIdentifier)
  const lastestIndex = contractFileList.length - 1
  return contractFileList[lastestIndex]
}

const saveCustomerContractFile = async (
  customerIdentifier: string,
  contractIdentifier: string,
  contractFile: File
): Promise<ClientContractFileInfo> => {
  const formData = new FormData()
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  formData.append('file', contractFile)
  formData.append('contractIdentifier', contractIdentifier)
  formData.append('customerIdentifier', customerIdentifier)

  return axios.post(`${baseUrl}/${customerIdentifier}/contracts/${contractIdentifier}/files`, formData, config)
}

export {
  createClientContract,
  updateClientContract,
  getCustomerContractsFilesList,
  getLatestContract,
  saveCustomerContractFile,
}
