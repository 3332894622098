import React from 'react'
import { AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { CopyAll } from '@mui/icons-material'

interface PassordViewProps {
  open: boolean
  handleModalClose: () => void
  password: string
  showAlert: (value: AlertColor, text: string) => void
}

const PasswordView: React.FC<PassordViewProps> = ({ open, handleModalClose, password, showAlert }) => {
  const copyPassword = (): void => {
    navigator.clipboard.writeText(password)
    showAlert('success', 'Senha copiada para a área de transferência')
  }

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }}>Senha cadastrada</DialogTitle>
      <DialogContent dividers>
        <Typography>A senha só será válida no primeiro login.</Typography>
        <br />
        <Typography style={{ textAlign: 'center' }}>
          <strong>
            <span data-cy="user-new-password">{password}</span>
            <Button onClick={copyPassword}>
              <CopyAll />
            </Button>
          </strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-cy="password-view-dialog-close" onClick={handleModalClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PasswordView
