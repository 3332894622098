import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import Icons from '../../../assets/icons/svgIcons'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import DeleteConfirmation, { DeleteItemText } from '../../../components/DeleteConfirmation/DeleteConfirmation'
import { ApplicationColors } from '../../../utils/applicationColors'
import { featureAccessCheck, iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import Maintenance from '../../../components/Maintenance/Maintenance'
import type { Vehicle } from '../../../models/Vehicle/Vehicle'
import type { CompanyMinimalInfo } from '../../../models/Company/Company'
import SelectCompany, { type SelectCompanyOptions } from '../../../components/Form/Select/SelectCompany'
import { deleteVehicle, getVehiclesByPartnerIdentifier } from '../../../services/Vehicle/VehicleService'
import { getAllPartners } from '../../../services/Partner/PartnerService'

interface VehicleListProps {
  isPageInMaintenance?: boolean
}

const VehicleList: React.FC<VehicleListProps> = ({ isPageInMaintenance }) => {
  const [vehicles, setVehicles] = React.useState<Vehicle[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<any>(null)
  const [itemToChange, setItemToChange] = React.useState<Vehicle>()
  const { setTitle, setCustomHeaderContent, showAlert, setIsShowLoading, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const [selectedTransporter, setSelectedTransporter] = React.useState<CompanyMinimalInfo>()
  const [transporters, setTransporters] = React.useState<CompanyMinimalInfo[]>([])
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })

  const navigate = useNavigate()
  const vehiclesMap = [
    { key: 'license', value: 'Placa' },
    { key: 'make', value: 'Marca' },
    { key: 'model', value: 'Modelo' },
  ]

  const handleChangeTransporter = (event: React.SyntheticEvent, value: SelectCompanyOptions | null): void => {
    if (value) {
      setLoading(true)
      setSelectedTransporter(value)
      getVehiclesByPartnerIdentifier(value?.identifier)
        .then((data) => {
          setVehicles(data)
        })
        .catch(() => {
          showAlert('error', 'Erro ao carregar Veículos. Tente novamente mais tarde.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleClickNew = React.useCallback(() => {
    navigate('/main/vehicle/form')
  }, [navigate])

  const handleEdit = (item: Vehicle): void => {
    navigate(`/main/vehicle/form/${item.identifier}`)
  }

  const openDeleteConfirmation = (item: Vehicle): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({
      title: 'Excluir veículo',
      item: 'o veículo',
      name: item.license,
    })
  }

  const handleDelete = (item: Vehicle): void => {
    setIsShowLoading(true)
    deleteVehicle(item.identifier)
      .then(() => {
        showAlert('success', 'Veículo excluído com sucesso.')
      })
      .catch(() => {
        showAlert('error', 'Erro ao excluir veículo.')
      })
      .finally(() => {
        setIsShowLoading(false)
        setVehicles(vehicles.filter((v) => v !== item))
      })
  }

  const handleDeleteConfirmationClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleDeleteConfirmation = (): void => {
    if (itemToChange) {
      handleDelete(itemToChange)
    }
    setItemToChange(undefined)
    handleDeleteConfirmationClose()
    setIsOpen(false)
  }

  const customHeaderContent: JSX.Element = React.useMemo(
    () =>
      featureAccessCheck('vehicle-write') && (
        <IconButton aria-label="Novo veículo" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ),
    [handleClickNew]
  ) as JSX.Element

  const handleInit = React.useCallback(async (): Promise<void> => {
    setTitle('Listar Veículos')
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar veículos', path: '/main/vehicle/list' }])
    setCustomHeaderContent(customHeaderContent)
    setIsShowLoading(true)
    try {
      const partnerCompanies = await getAllPartners()

      setTransporters(partnerCompanies)
      setSelectedTransporter(partnerCompanies[0])

      setVehicles(await getVehiclesByPartnerIdentifier(partnerCompanies[0].identifier))
    } catch {
      showAlert('error', 'Erro ao carregar Veículos.')
    } finally {
      setIsShowLoading(false)
    }
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert, setItemsBreadCrumb, setIsShowLoading])

  React.useEffect(() => {
    if (!isPageInMaintenance) handleInit()
  }, [handleInit, isPageInMaintenance])

  const handleMoreOptions = (item: Vehicle, index?: number, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleClose = (): void => {
    setIsOpen(false)
    setItemToChange(undefined)
  }

  return isPageInMaintenance ? (
    <Maintenance />
  ) : (
    <>
      <DeleteConfirmation
        handleConfirmDelete={handleDeleteConfirmation}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteItemTexts={deleteItemTexts}
      />
      {featureAccessCheck('vehicle-filter') && (
        <Card className="card-filters" variant="outlined">
          <SelectCompany
            id="transporter"
            label="Empresa Parceira"
            company={selectedTransporter}
            options={transporters}
            onChange={handleChangeTransporter}
          />
        </Card>
      )}
      <div className={featureAccessCheck('vehicle-filter') ? 'filter--open' : 'filter--closed'}>
        <DataTable
          data={vehicles}
          dataMap={vehiclesMap}
          loading={loading}
          actions={
            iconAccessCheck('vehicle-edit') || iconAccessCheck('vehicle-delete')
              ? [
                  {
                    label: 'Opções',
                    icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                    fn: handleMoreOptions,
                  },
                ]
              : []
          }
        />
        <EditDeletePopover
          editFunction={iconAccessCheck('vehicle-edit') ? handleEdit : undefined}
          deleteFunction={iconAccessCheck('vehicle-delete') ? openDeleteConfirmation : undefined}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClose}
          anchor={anchor}
        />
      </div>
    </>
  )
}

export default VehicleList
