import axios from 'axios'
import getEndpointUrl from '../../utils/url-resolver'
import { ChangePasswordParam } from '../../models/User/user'

const baseUrl = `${getEndpointUrl('company')}/v2/user`

const createNewPassword = (passwords: ChangePasswordParam): Promise<void> =>
  axios.put(`${baseUrl}/change-password`, passwords).then((response) => response.data)

export { createNewPassword }
