import { ISystemUpdates2 } from '../../models/SystemUpdate/update'
import { CargoResponse } from '../../models/User/user'
import slugfyString from '../../utils/SlugfyString'

const reciclaEmployeesOnly = [
  'Administrador',
  'Tecnologia',
  'Sucesso do Cliente',
  'Experiência do Cliente',
  'Analista de Dados',
]

const systemUpdatesInfo: ISystemUpdates2[] = [
  {
    title: 'Novo filtro de usuários por email',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Facilitamos a busca por usuários com um filtro por email.',
    changesMade: ['Novo filtro que oferece busca de usuários, na respectiva página de listagem, por email.'],
    date: '13 de janeiro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Senha de usuário automática',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Agora as senhas de usuários são geradas automaticamente e válidas apenas para o primeiro login.',
    changesMade: [
      'A senha de um novo usuário somente é gerada automaticamente.',
      'Na edição de usuário, opcionalmente é possível gerar uma nova senha aleatória.',
    ],
    date: '30 de dezembro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Número de endereço facultativo em cadastros',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Tornamos facultativo o campo "Número", da seção de endereço, no cadastro de clientes e parceiros.',
    changesMade: ['Campo de número da seção de endereço, nos cadastros de empresas, não é mais obrigatório.'],
    date: '03 de dezembro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Novo permissionamento para Clientes e Parceiros',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Separamos os permissionamentos de clientes e parceiros, para maior controle e segurança.',
    changesMade: [
      'Adicionamos permissionamentos de leitura, criação, edição, filtragem e exclusão de dados, de forma separada, para clientes e parceiros.',
    ],
    date: '07 de novembro de 2024',
    allowedRoles: ['Administrador'],
  },
  {
    title: 'Listagem de resíduos com itens clicáveis',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Nos formulários de novo MTR e Modelo de MTR, a listagem de resíduos adicionados é clicável.',
    changesMade: [
      'Agora cada resíduo adicionado pode ter sua caixa de edição acessada com um simples clique sobre o item.',
    ],
    date: '06 de novembro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Facilitação na vinculação de empresas e funcionários',
    get identifier() {
      return slugfyString(this.title)
    },
    description:
      'Com um clique o funcionário pode selecionar ou desmarcar todas as empresas no formulário de funcionários.',
    changesMade: ['Botão de seleção de todas as empresas.', 'Botão de desmarcar todas as empresas.'],
    date: '31 de outubro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Nova opção na documentação dos parceiros',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'O documento CND Municipal foi adicionado ao escopo de documentos ambientais dos parceiros.',
    changesMade: [
      'Possibilidade de adicionar a CND Municipal na aba de documentação dos parceiros, ao editá-los.',
      'Possibilidade de visualizar a CND Municipal na aba de documentação ambiental, bem como sua data de validade.',
    ],
    date: '31 de outubro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Confirmação de MTR com checagem de dados',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Oferecemos agora a possibilidade de confirmação dos dados do MTR antes de sua geração.',
    changesMade: [
      'Ao salvar um MTR uma caixa de informações é apresentada ao usuário, assim ele pode confirmar os dados ou prever possíveis inconsistências.',
    ],
    date: '31 de outubro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Indicação do status de funcionamento da FEAM',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Agora é possível consultar o status de funcionamento da FEAM diretamente no sistema.',
    changesMade: ['Adição de indicador visual de status da FEAM nas páginas de listagem e formulário de MTR.'],
    date: '08 de outubro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Facilitação da edição de resíduos.',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Facilitamos a edição da quantidade de cada resíduo na geração de MTR.',
    changesMade: [
      'Em vez de acessar cada resíduo individualmente para editar sua quantidade, você pode fazer isso diretamente na listagem.',
    ],
    date: '03 de outubro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Nova checagem de CNPJ no cadastro e edição de clientes.',
    get identifier() {
      return slugfyString(this.title)
    },
    description:
      'Ao cadastrar ou atualizar um cliente, é feita uma verificação se o CNPJ inserido já está atrelado a outra empresa.',
    changesMade: [
      'Checagem instantânea de CNPJ no momento do cadastro ou edição de clientes, proporcionando mais transparência e eficiência no processo.',
    ],
    date: '01 de outubro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Novo campo para salvar o arquivo do contrato do Cliente',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Atualização que adiciona um campo para salvar o arquivo PDF do contrato do Cliente.',
    changesMade: [
      'Adiciona o campo de arquivo PDF do contrato a aba "Dados do contrato" no formulário de cadastro e edição do Cliente.',
      'Adiciona o link para o arquivo do contrato a aba de "Dados do Contrato" na visualização de dados do Cliente.',
    ],
    date: '26 de setembro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Novos campos sobre resíduos na geração de MTR',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Agora você pode inserir mais informações internas do gerador sobre o resíduo.',
    changesMade: [
      'Adicionamos o campo "Código Interno no Gerador".',
      'Substituímos o antigo campo "Observações" por "Observações Internas do Gerador".',
    ],
    date: '19 de setembro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Visualização financeira otimizada',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Agora você pode consultar os dados financeiro do Cliente com poucos cliques.',
    changesMade: [
      'Adicionamos à caixa de visualização de dados do Cliente a aba "Financeiro", com tabelas de Custos, Receitas, Comissões e valores totais.',
    ],
    date: '10 de setembro de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Otimização e reparação do sistema para a melhor experiência do usuário.',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Nesta primeira otimização, trazemos melhorias de usabilidade e correções de bugs.',
    changesMade: [
      'Tornamos os campos de veículo e motorista, do formulário de Novo MTR, facultativos e de valores livres.',
      'Disponibilizamos a criação e visualização de observações (sobre clientes) para mais tipos de usuários.',
      'Corrigimos as instabilidades na criação de Modelos de MTR.',
      'Melhoramos a visualização do Monitor Verde e seu filtro de seleção de empresas.',
      'Melhoramos a visibilidade de campos obrigatórios não preenchidos no formulário de novo Cliente',
    ],
    date: '28 de agosto de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Visualização ágil de documentação de parceiros',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Com um clique você pode ver a documentação de todos os parceiros de uma só vez.',
    changesMade: [
      'Disponibiliza a opção "Todos os parceiros" na página "Documentação Ambiental". Tal opção disponibiliza uma listagem completa de todos os parceiros que atendem as empresas vinculadas a sua conta.',
    ],
    date: '20 de agosto de 2024',
    allowedRoles: ['Acesso Básico'],
  },
  {
    title: 'Visualização de dados dos Parceiros',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'É possível visualizar as informações do Parceiro com um clique.',
    changesMade: [
      'Disponibiliza a visualização detalhada de dados cadastrados na própria listagem de parceiros.',
      'Facilita o acesso a edição do parceiro selecionado.',
    ],
    date: '12 de agosto de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Visualização de dados dos Clientes',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Agora é possível visualizar as informações do Cliente com um clique.',
    changesMade: [
      'Adiciona uma visualização dinâmica e detalhada de cada empresa, na página de listagem de clientes.',
      'O acesso a edição do cliente selecionado foi facilitado através de um botão de edição na caixa de informações aberta.',
    ],
    date: '09 de agosto de 2024',
    allowedRoles: reciclaEmployeesOnly,
  },
  {
    title: 'Agilidade na navegação',
    get identifier() {
      return slugfyString(this.title)
    },
    description: 'Selecione a unidade almejada e navegue entre as páginas sem perder a seleção.',
    changesMade: [
      'Persistência de uma empresa selecionada ao navegar entre as demais páginas, seja de listagem ou formulários.',
    ],
    date: '25 de julho de 2024',
    allowedRoles: ['Acesso Básico'],
  },
]

export const getUpdatesByUserPermissions = (userCargos: CargoResponse[]): ISystemUpdates2[] => {
  const cargoNames = userCargos.map((cargo) => cargo.name)
  if (cargoNames.includes('Administrador')) return systemUpdatesInfo.slice(0, 5)

  return systemUpdatesInfo
    .filter((update) => cargoNames.some((cargo) => update.allowedRoles.includes(cargo)))
    .slice(0, 5)
}

export const getLatestUpdate = (userCargos: CargoResponse[]): ISystemUpdates2 => {
  return getUpdatesByUserPermissions(userCargos)[0]
}

export const getUpdateByIdentifier = (identifier: string): ISystemUpdates2 | undefined => {
  return systemUpdatesInfo.find((update) => update.identifier === identifier)
}

export default systemUpdatesInfo
