import React from 'react'
import { Grid, FormLabel, Typography, Chip } from '@mui/material'
import { PartnerResponse } from '../../../models/Partner/Partner'
import useStyles from '../Styles/ViewInfoModalStyle'
import { partnerServiceTypesOptions } from '../../../models/Company/FormOptions/PrimaryInfoOptions'
import maskCNPJ from '../../../utils/masks/maskCNPJ'

interface PartnerPrimaryDataProps {
  partner: PartnerResponse
}

const PartnerPrimaryData: React.FC<PartnerPrimaryDataProps> = ({ partner }) => {
  const classes = useStyles()

  const partnerAddresNumber = partner.primaryData.address.number ? `${partner.primaryData.address.number},` : ''
  const partnerAddresComplement = partner.primaryData.address.complement
    ? `${partner.primaryData.address.complement}.`
    : ''

  const partnerAdress = `${partner.primaryData.address.street}, ${partnerAddresNumber} ${partnerAddresComplement} ${partner.primaryData.address.district}, ${partner.primaryData.address.city} - ${partner.primaryData.address.state}, ${partner.primaryData.address.zipcode}`

  return (
    <Grid container spacing={2} className={classes.mainCard}>
      <Grid item xs={12} key="primary-data-title">
        <h2 className={classes.tabTitle}>Dados Primários</h2>
      </Grid>
      <Grid item xs={6} key="company-name">
        <FormLabel className={classes.labelStyle} component="label">
          Razão Social
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-name">
          {partner.primaryData.companyName}
        </Typography>
      </Grid>
      <Grid item xs={6} key="documents">
        <FormLabel className={classes.labelStyle} component="label">
          CNPJ
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-cnpj">
          {maskCNPJ(partner.primaryData.cnpj)}
        </Typography>
      </Grid>
      <Grid item xs={6} key="icms-taxPayer">
        <FormLabel className={classes.labelStyle} component="label">
          Código de Unidade
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-icms-taxPayer">
          {partner.primaryData.unitCode || 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="service-type">
        <FormLabel className={classes.labelStyle} component="label">
          Tipo(s) de Serviço
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-service-type" sx={{ display: 'flex' }}>
          {partner.partnerType.length > 0
            ? partner.partnerType
                .map((type) => partnerServiceTypesOptions.find((option) => option.value === type)?.label)
                .filter(Boolean)
                .join(', ')
            : 'Não informado'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="icms-taxPayer">
        <FormLabel className={classes.labelStyle} component="label">
          Contribuinte ICMS
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-icms-taxPayer">
          {partner.primaryData.icmsTaxpayer ? 'Sim' : 'Não'}
        </Typography>
      </Grid>
      <Grid item xs={6} key="taxRegime">
        <FormLabel className={classes.labelStyle} component="label">
          Regime Tributário
        </FormLabel>
        <Typography variant="body1" data-cy="view-modal-tax-regime">
          {partner.primaryData.taxRegime}
        </Typography>
      </Grid>
      <Grid item xs={12} key="address-title">
        <h2 className={classes.tabTitle}>Endereço</h2>
      </Grid>
      <Grid item xs={12} key="address" data-cy="partner-address-info-modal">
        <Typography variant="body1">{partnerAdress}</Typography>
      </Grid>
    </Grid>
  )
}

export default PartnerPrimaryData
