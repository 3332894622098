import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

interface ChangePasswordModalProps {
  open: boolean
  handleClose: () => void
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }} data-cy="change-password-modal-title">
        Alteração de Senha
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Para sua segurança, pedimos que atualize a sua senha cadastrada para voltar a utilizar o sistema.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePasswordModal
